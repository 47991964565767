import React from 'react'
const RefundAndCancellation = () => {
  return (
    <div>
      <section className="pageheader-section" style={{ backgroundImage: 'url(/images/bgpages.jpg)' }}>
        <div className="container">
          <div className="section-wrapper text-center text-uppercase">
            <h2 className="pageheader-title">Refund And Cancellation</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Refund&Cancellation</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <div className="info-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header">
            <h2>Refund And Cancellation Policies</h2>
          </div>
          <div className="section-wrapper">
            <div className="row justify-content-center g-4">
              <div className="col-lg-12 col-sm-12 col-12">
                <div style={{ textAlign: "justify" }} className="contact-item fs-3">
                  <p>
                    LuckyStar365 ENTERPRISES believes in helping its customers as far as possible, and has therefore a liberal
                    cancellation policy. Under this policy:
                    • Cancellations will be considered only if the request is made immediately after placing the order.
                    However, the cancellation request may not be entertained if the orders have been communicated to the
                    vendors/merchants and they have initiated the process of shipping them.
                    • LuckyStar365 ENTERPRISES does not accept cancellation requests for perishable items like flowers, eatables
                    etc. However, refund/replacement can be made if the customer establishes that the quality of product
                    delivered is not good.
                    • In case of receipt of damaged or defective items please report the same to our Customer Service team.
                    The request will, however, be entertained once the merchant has checked and determined the same at his
                    own end. This should be reported within 2 Days days of receipt of the products. In case you feel that the
                    product received is not as shown on the site or as per your expectations, you must bring it to the notice of
                    our customer service within 2 Days days of receiving the product. The Customer Service Team after
                    looking into your complaint will take an appropriate decision.
                    • In case of complaints regarding products that come with a warranty from manufacturers, please refer
                    the issue to them. In case of any Refunds approved by the LuckyStar365 ENTERPRISES, it’ll take 6-8 Days
                    days for the refund to be processed to the end customer.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <div className="contact-section">
    <div className="contact-top padding-top padding-bottom bg-attachment" style={{backgroundImage: 'url(assets/images/video/bg.jpg)'}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <div className="contact-form-wrapper text-center">
              <div className="section-header">
                <h2>Fill The Form Below So We Can Get To Know You And Your Needs Better.</h2>
              </div>
              <form className="contact-form" action="contact.php" id="contact-form" method="POST">
                <div className="form-group">
                  <input type="text" placeholder="Your Name" id="name" name="name" required="required" />
                </div>
                <div className="form-group">
                  <input type="text" placeholder="Your Email" id="email" name="email" required />
                </div>
                <div className="form-group">
                  <input type="text" placeholder="Phone" id="phone" name="phone" required />
                </div>
                <div className="form-group">
                  <input type="text" placeholder="Subject" id="subject" name="subject" required />
                </div>
                <div className="form-group w-100">
                  <textarea name="message" rows={8} id="message" placeholder="Your Message" required defaultValue={""} />
                </div>
                <div className="form-group w-100 text-center">
                  <button className="default-button" type="submit"><span>Send our Message</span></button>
                </div>
              </form>
              <p className="form-message" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="contact-bottom">
      <div className="contac-bottom">
        <div className="row justify-content-center g-0">
          <div className="col-12">
            <div className="location-map">
              <div id="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.229026386336!2d75.76772217489444!3d26.896225660799896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db59e52d6cb5d%3A0x13f14d327ae6a92!2sShyam%20nagar%20metro%20station!5e0!3m2!1sen!2sin!4v1702556120245!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
    </div>

  )
}


export default RefundAndCancellation