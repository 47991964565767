let game_name_second = [
    { id: 11, name: "Jackpot 3 Patti", },
    { id: 12,   name: "Jandi Munda", },
    { id: 13,  name: "Poker games", },
    { id: 14, name: "Private Rummy", },
    { id: 15,   name: "Private Table", },
    { id: 16, name: "Red & Black", },
    { id: 17,    name: "Roulette", },
    { id: 18,   name: "Rummy games", },
    { id: 19, name: "3 patti games", },
    { id: 20,  name: "Zoo Roulette ", },
];

export default game_name_second;
