import p23_img from "./download (3) (1).jpeg";
import p24_img from "./download (2) (1).jpeg";



let popular_gamesecond = [
    {  id: 23,
        image: p23_img,
        name: "Dragon & Tiger",
        
    },
    {    id: 24,
        image: p24_img,
        name: "Roulette games",
        
},
]


export default popular_gamesecond;