import p21_img from "./02 (1).jpg";
import p22_img from "./casinogamein.jpg";




let popular_game = [
    {   id: 21,
        image: p21_img,
        name: " 3 patti games",
        
    },
    {  id: 22,
        image: p22_img,
        name: "Color prediction",
        
    },
  
]

export default popular_game;