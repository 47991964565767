let game_name = [
  { id: 1, name: "Andar Bahar game" },
  { id: 2, name: "Baccarat Game" },
  { id: 3, name: "Colour Prediction" },
  { id: 4, name: "Deal Rummy" },
  { id: 5, name: "Car Roulette" },
  { id: 6, name: "Point Rummy" },
  { id: 7, name: "Dragon & Tiger" },
  { id: 8, name: "Head & Tail" },
  { id: 9, name: "Custom Bot" },
  { id: 10, name: "Pool Rummy" },
];

export default game_name;
