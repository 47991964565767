import React from "react";
const PrivacyPolicy = () => {
  return (
    <div>
      <section
        className="pageheader-section"
        style={{ backgroundImage: "url(/images/bgpages.jpg)" }}
      >
        <div className="container">
          <div className="section-wrapper text-center text-uppercase">
            <h2 className="pageheader-title">PrivacyPolicy</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  PrivacyPolicy
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <div className="info-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header">
            <h2>Privacy Policy</h2>
          </div>
          <div className="section-wrapper">
            <div className="row justify-content-center g-4">
              <div className="col-lg-12 col-sm-12 col-12">
                <div style={{ textAlign: "justify" }} className="contact-item fs-4 ">
                  <p>
                    This
                    privacy policy (“Policy”) relates to the manner LuckyStar365
                    ENTERPRISES (“we”, “us”, “our”) in which we use, handle and
                    process the data that you provide us in connection with
                    using the products or services we offer. By using this
                    website or by availing goods or services offered by us, you
                    agree to the terms and conditions of this Policy, and
                    consent to our use, storage, disclosure, and transfer of
                    your information or data in the manner described in this
                    Policy. We are committed to ensuring that your privacy is
                    protected in accordance with applicable laws and
                    regulations. We urge you to acquaint yourself with this
                    Policy to familiarize yourself with the manner in which your
                    data is being handled by us. LuckyStar365 ENTERPRISES may change
                    this Policy periodically and we urge you to check this page
                    for the latest version of the Policy in order to keep
                    yourself updated. What data is being collected We may
                    collect the following information from you: Name Contact
                    information including address and email address Demographic
                    information or, preferences or interests Personal Data or
                    Other information relevant/ required for providing the goods
                    or services to you The meaning of Personal Data will be as
                    defined under relevant Indian laws Note: Notwithstanding
                    anything under this Policy as required under applicable
                    Indian laws, we will not be storing any credit card, debit
                    card or any other similar card data of yours. Please also
                    note that all data or information collected from you will be
                    strictly in accordance with applicable laws and guidelines.
                    What we do with the data we gather We require this data to
                    provide you with the goods or services offered by us
                    including but not limited, for the below set out purposes:
                    Internal record keeping. For improving our products or
                    services. For providing updates to you regarding our
                    products or services including any special offers. To
                    communicate information to you For internal training and
                    quality assurance purposes Who do we share your data with We
                    may share your information or data with: Third parties
                    including our service providers in order to facilitate the
                    provisions of goods or services to you, carry out your
                    requests, respond to your queries, fulfil your orders or for
                    other operational and business reasons. With our group
                    companies (to the extent relevant) Our auditors or advisors
                    to the extent required by them for performing their services
                    Governmental bodies, regulatory authorities, law enforcement
                    authorities pursuant to our legal obligations or compliance
                    requirements. How we use cookies We use "cookies" to collect
                    information and to better understand customer behaviour. You
                    can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to avail our goods or
                    services to the full extent. We do not control the use of
                    cookies by third parties. The third party service providers
                    have their own privacy policies addressing how they use such
                    information. Your rights relating to your data Right to
                    Review - You can review the data provided by you and can
                    request us to correct or amend such data (to the extent
                    feasible, as determined by us). That said, we will not be
                    responsible for the authenticity of the data or information
                    provided by you. Withdrawal of your Consent - You can choose
                    not to provide your data, at any time while availing our
                    goods or services or otherwise withdraw your consent
                    provided to us earlier, in writing to our email ID:
                    Raahibet@gmail.comIn the event you choose to not provide or
                    later withdraw your consent, we may not be able to provide
                    you our services or goods.Please note that these rights are
                    subject to our compliance with applicable laws. How long
                    will we retain your information or data? We may retain your
                    information or data (i) for as long as we are providing
                    goods and services to you; and (ii) as permitted under
                    applicable law, we may also retain your data or information
                    even after you terminate the business relationship with us.
                    However, we will process such information or data in
                    accordance with applicable laws and this Policy. Data
                    Security We will use commercially reasonable and legally
                    required precautions to preserve the integrity and security
                    of your information and data. Queries/ Grievance Officer For
                    any queries, questions or grievances about this Policy,
                    please contact us using the contact information provided on
                    this website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
